import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const LogoSlider = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        left: -20,
        zIndex: 1,
        color: "black",
        cursor: "pointer",
        fontSize: "25px",
        lineHeight: "25px",
      }}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        right: -20,
        zIndex: 1,
        color: "black",
        cursor: "pointer",
        fontSize: "25px",
        lineHeight: "25px",
      }}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 765, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    "/images/logos/HNB-Logo-1.jpg",
    "/images/logos/Link-Natural-Logo.jpg",
    "/images/logos/RDA-logo.jpg",
    "/images/logos/sevanatha-urban-resource-centre-41846.jpg",
    "/images/logos/SL-Navy.jpg",
    "/images/logos/Sri_Lanka_Coast_Guard.jpg",
    "/images/logos/1-2.jpg",
    "/images/logos/Asia-Foundation-logo.jpg",
    "/images/logos/Coke-Logo-1.jpg",
    "/images/logos/PC-logo-with-JKH-03.png",
    "/images/logos/HDFC_LOGO.jpg",
    "/images/logos/Sri_Lanka_Coast_Guard.jpg",
  ];

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="container">
            <center>
              <img src={image} alt="logo" className="logo-image" />
            </center>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoSlider;
