import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import Logos1 from "./logos-1";
import Slider from "../../components/zoomImageSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

export default function Initiatives() {
  const zoomImages = [
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/initiatives/initiatve_slide-01-scaled.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/initiatives/initiatve_slide-02-scaled.jpg",
  ];

  return (
    <div>
      <NavBar />
      <div style={{ position: "relative" }}>
        <Slider images={zoomImages} />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            zIndex: 0,
          }}
        ></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic">
              WE BRING SRI LANKA
              <br />
              ONE STEP TOWARDS
              <br />
              SUSTAINABILITY
            </h1>
          </div>
        </div>
      </div>

      {/* session - 1 */}
      {/* session - 11 */}
      <div>
        <center>
          <div className="page-content">
            <center>
              <div style={{ marginTop: "100px", marginBottom: "50px" }}>
                <div
                  style={{
                    margin: "50px",
                  }}
                >
                  <div className="s11-3rd">
                    <div className="m-t-f">
                      <h2 className="topic">PARTNERSHIPS & COLLABORATIONS</h2>
                    </div>
                    <p className="para">
                      We invest heavily into bringing local players to the
                      recycling practice. By involving country-wide
                      organizations in our efforts, we ensure that
                      sustainability receives plenty of attention and backing to
                      support the eco effort even at the lowest levels.
                    </p>
                  </div>
                </div>
              </div>
            </center>

            <div
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                marginBottom: "130px",
              }}
            >
              <Logos1 />
            </div>
          </div>
        </center>
      </div>

      <div>
        <div className="initatives-card-grid">
          <a
            href="/ocean-waste-management/"
            className="link"
            aria-label="button"
          >
            <div
              className="initatives-card"
              style={{
                backgroundImage:
                  'url("https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/initiatives/closeup-green-sea-turtle-swimming-underwater-lights-1.jpg")',
              }}
            >
              <center>
                <div className="initatives-card-content">
                  <h2 className="initatives-card-title">
                    COASTAL PLASTIC
                    <br />
                    WASTE MANAGEMENT
                  </h2>
                  <br />
                  <div style={{ marginTop: "100px", marginBottom: "60px" }}>
                    <a
                      href="/ocean-waste-management/"
                      className="initatives-button"
                    >
                      See Initiative{" "}
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </a>
                  </div>
                </div>
              </center>
            </div>
          </a>

          <a
            href="/pilgrimage-waste-management/"
            className="link"
            aria-label="button"
          >
            <div
              className="initatives-card"
              style={{
                backgroundImage:
                  'url("https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/initiatives/pilgramige1.jpg")',
              }}
            >
              <center>
                <div className="initatives-card-content">
                  <h2 className="initatives-card-title">
                    PILGRIMAGE WASTE
                    <br />
                    MANAGEMENT
                  </h2>
                  <div style={{ marginTop: "100px", marginBottom: "60px" }}>
                    <a
                      href="/pilgrimage-waste-management/"
                      className="initatives-button"
                    >
                      See Initiative{" "}
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </a>
                  </div>
                </div>
              </center>
            </div>
          </a>
        </div>
      </div>

      <div style={{ marginTop: "100px", marginBottom: "100px" }}>
        <a href="/other-initiatives/" className="link" aria-label="button">
          <div
            className="initatives-card-2"
            style={{
              backgroundImage:
                'url("https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/initiatives/20ebf3bc-plasticos-de-usar-y-tirar.jpg")',
            }}
          >
            <center>
              <div className="initatives-card-content">
                <h2 className="initatives-card-title">OTHER INITIATIVES</h2>
                <div style={{ marginTop: "100px", marginBottom: "60px" }}>
                  <a href="/other-initiatives/" className="initatives-button">
                    See Initiative{" "}
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </a>
                </div>
              </div>
            </center>
          </div>
        </a>
      </div>

      <Footer />
    </div>
  );
}
